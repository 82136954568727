import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import Button from '@mui/material/Button';
import CookieConsent from "react-cookie-consent";

import RootContext from './context/RootContext';
import { PackageContext } from './context/PackageContext';
import { AuthContext } from './context/AuthContext';
import themeConfig from './themeConfig';
import NavBar from './component/NavBar';
import Footer from './component/Footer';
import Home from './page/Home';
import Page404 from './page/Page404';
import AuthModal from './component/AuthModal';
import AboutUs from './page/About/AboutUs';
import AboutBusiness from './page/About/AboutBusiness'
import AboutEarnMoney from './page/About/AboutEarnMoney';
import SendPackage from './page/SendPackage';
import Privacy from './page/Privacy';
import TermsAndConditions from './page/TermsAndConditions';
import Settings from './page/Settings';
import TrackParcel from './page/TrackParcel';
import MyParcels from './page/MyParcels';
import DataSubjectAccessRequest from './page/DSR';
import Help from './page/Help';

const ProtectePackagedRoute = ({ component: Component, ...rest }) => {
    const {priceResult} = useContext(PackageContext);
    return(
        <Route
            {...rest}
            render={()=>{
                return (
                    (priceResult?.priceId === undefined ) ? <Redirect to="/" /> :  <Component />
                )
            }}
        />
    )
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const {user} = useContext(AuthContext);
    return(
        <Route
            {...rest}
            render={()=>{
                return (
                    user ? <Component /> : <Redirect to="/" />
                )
            }}
        />
    )
}

function App() {
    return (
        <ThemeProvider theme={themeConfig}>
            <CssBaseline />
            <BrowserRouter>
                <RootContext>
                    <NavBar />
                    <AuthModal />
                    <CookieConsent
                        buttonStyle={{ backgroundColor: "#0240AD", color:'white' }}
                    >
                        This website uses cookies to enhance the user experience.
                        Visit our <a href='/privacy' style={{color:'white'}} target='_blank' rel="noopener noreferrer" >cookie policy</a> to find out more.
                    </CookieConsent>
                    <Switch>

                        <Route exact path={'/about/us'} component={AboutUs} />
                        <Route exact path={'/about/earn'} component={AboutEarnMoney} />
                        <Route exact path={'/about/business'} component={AboutBusiness} />
                        <Route exact path={'/dsr'} component={DataSubjectAccessRequest} />
                        <Route exact path={'/help'} component={Help} />

                        {/*<Route exact path={'/settings/password'} component={ResetPassword} />*/}
                        <AuthenticatedRoute exact path={'/myparcels'} component={MyParcels} />
                        <ProtectePackagedRoute exact  path={'/send-package'} component={SendPackage} />
                        <Route exact path={'/settings'} component={Settings} />
                        <Route exact path={'/privacy'} component={Privacy} />
                        <Route exact path={'/terms'} component={TermsAndConditions} />
                        <Route exact path={'/track'} component={TrackParcel} />

                        <Route exact path={'/'} component={Home} />
                        <Route component={Page404} />
                    </Switch>
                    <Footer />
                </RootContext>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
